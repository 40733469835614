import { europeanConstant } from "../_constants/european.constant";
import { status } from "../_constants/commonLhd";

let initialState = {};

export function european(state = initialState, action) {
  switch (action.type) {
    case europeanConstant.european_REQUEST:
      return {
        ...state,
        european_status: status.IN_PROGRESS,
        european_data: null,
      };
    case europeanConstant.european_SUCCESS:
      return {
        ...state,
        european_status: status.SUCCESS,
        european_data: action.data,
      };
    case europeanConstant.european_FAILURE:
      return {
        ...state,
        european_status: status.FAILURE,
        european_data: action.data,
      };
    default:
      return state;
  }
}
