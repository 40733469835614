import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import plusicon from "../../ulits/images/plus-icon.svg";
import socialicon3 from "../../ulits/images/social-icon3.svg";
import shareicon from "../../ulits/images/share-icon.svg";
import { Modal } from "react-bootstrap";
import driveicon from "../../ulits/images/drive_icon.svg";
import { configs } from "../../config";

function CarDetails() {
  const [loader, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const [cars, setCars] = useState(location.state);
  console.log("cars", cars);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleModalClose = () => {
    setModal(false);
  };
  const handleModalOpen = () => {
    setModal(true);
  };

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="car-listing-section car-details">
            <div className="container">
              <div className="modern-filter-listing">
                <div className="car-listing-left">
                  <h5>£{cars.mileage}</h5>
                  <div className="details-box">
                    <div className="inner-title-text">
                      <h3>BODY</h3>
                      <p>{cars.model}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>MILEAGE</h3>
                      <p>{cars.mileage} MILES</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>FUEL TYPE</h3>
                      <p>{cars.fuelType}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>ENGINE</h3>
                      <p>{cars.engine}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>TRANSMISSION</h3>
                      <p>{cars.transmission}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>EXTERIOR COLOUR</h3>
                      <p>{cars.exteriorColor}</p>
                    </div>
                    <div className="inner-title-text">
                      <h3>INTERIOR COLOUR</h3>
                      <p>{cars.interiorColor}</p>
                    </div>
                  </div>
                  <div className="details-box">
                    <h2>FINANCING CALCULATOR</h2>
                    <ul>
                      <li>VEHICLE PRICE (£)</li>
                      <li>INTEREST RATE (%)</li>
                      <li>PERIOD (MONTH)</li>
                      <li>DOWN PAYMENT (£)</li>
                    </ul>
                    <button className="primary-btn sm" title="CALCULATE">
                      CALCULATE
                    </button>
                  </div>
                </div>
                <div className="car-listing-right">
                  <div className="car-listing-right-top-left-text">
                    <h1>{cars.details}</h1>
                  </div>
                  <div className="car-list-btn">
                    <button
                      onClick={handleModalOpen}
                      className="primary-btn sm"
                    >
                      <img src={socialicon3} alt="" />
                      SCHEDULT TEST DRIVE
                    </button>
                    <button className="primary-btn sm">
                      <img src={plusicon} alt="" />
                      ADD TO COMPARE
                    </button>
                    <button className="primary-btn sm">
                      <img src={shareicon} alt="" /> SHARE THIS
                    </button>
                  </div>
                  <div className="car-list-img">
                    <img
                      src={`${configs.localApiUrl}${cars.image[0]}`}
                      alt=""
                    />
                  </div>
                  <p>{cars.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={modal}
        onHide={() => {
          handleModalClose();
        }}
        className="schedule-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="schedule-container">
            <img src={driveicon} alt="" />
            <h1>SCHEDULE A TEST DRIVE</h1>
            <p>DACIA DUSTER 2018 75000</p>
            <div className="schedule-form">
              <form>
                <input
                  type="text"
                  placeholder="NAME"
                  className="form-control"
                />
                <input
                  type="text"
                  placeholder="EMAIL"
                  className="form-control"
                />
                <input
                  type="text"
                  placeholder="PHONE"
                  className="form-control"
                />
                <input
                  type="text"
                  placeholder="BEST TIME"
                  className="form-control"
                />
                <button className="primary-btn sm" title="REQUEST">
                  REQUEST
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CarDetails;
