import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import Accordion from "react-bootstrap/Accordion";
import socialicon1 from "../../ulits/images/social-icon1.svg";
import socialicon2 from "../../ulits/images/social-icon2.svg";
import socialicon3 from "../../ulits/images/social-icon3.svg";
import socialicon4 from "../../ulits/images/social-icon4.svg";
import socialicon5 from "../../ulits/images/social-icon5.svg";
import socialicon6 from "../../ulits/images/social-icon6.svg";
import socialicon7 from "../../ulits/images/social-icon7.svg";
import socialicon8 from "../../ulits/images/social-icon8.svg";
import socialicon9 from "../../ulits/images/social-icon9.svg";
import socialicon10 from "../../ulits/images/social-icon10.svg";
import socialicon11 from "../../ulits/images/social-icon11.svg";
import locationicon from "../../ulits/images/location-icon.svg";
import carimg2 from "../../ulits/images/car_img2.png";
import milionicon from "../../ulits/images/milion_icon.svg";
import petrolicon from "../../ulits/images/petrol_icon.svg";
import manualicon from "../../ulits/images/manual_icon.svg";
import plusicon from "../../ulits/images/plus-icon.svg";
import bolteg from "../../ulits/images/bolteg.png";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "../../config";
import { homeAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";

function CarListing() {
  const [loader, setLoading] = useState(true);
  const [list, setList] = useState(false);
  const [handDrive, setHandDrive] = useState("left");
  const [cars, setCars] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const homeData = useSelector((state) => state.home);
  console.log(38, homeData);

  useEffect(() => {
    dispatch(homeAction.getCarsByTenantId());
  }, []);

  console.log(45, homeData.car_data);

  useEffect(() => {
    if (homeData && homeData.car_status === status.SUCCESS) {
      console.log("111", homeData);
      setCars(homeData.car_data);
    }
  }, [homeData.car_data]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleSelection = (event) => {
    setHandDrive(event.target.value);
  };

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="car-listing-section">
            <div className="container">
              <div className="modern-filter-listing">
                <div className="car-listing-left">
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon1} alt="location-icon" />
                          SORT BY
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="sort-list">
                          <div className="sort-by">
                            <label>
                              <input
                                type="radio"
                                value="RELEVANCE"
                                checked={handDrive === "RELEVANCE"}
                                onChange={handleSelection}
                              />
                              <span>RELEVANCE</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="PRICE (LOW TO HIGH)"
                                checked={handDrive === "PRICE (LOW TO HIGH)"}
                                onChange={handleSelection}
                              />
                              <span>PRICE (LOW TO HIGH)</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="PRICE (HIGH TO LOW)"
                                checked={handDrive === "PRICE (HIGH TO LOW)"}
                                onChange={handleSelection}
                              />
                              <span>PRICE (HIGH TO LOW)</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="DISTANCE"
                                checked={handDrive === "DISTANCE"}
                                onChange={handleSelection}
                              />
                              <span>DISTANCE</span>
                            </label>
                          </div>
                          <div className="sort-by">
                            <label>
                              <input
                                type="radio"
                                value="MILEAGE"
                                checked={handDrive === "MILEAGE"}
                                onChange={handleSelection}
                              />
                              <span>MILEAGE</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="AGE (NEWEST)"
                                checked={handDrive === "AGE (NEWEST)"}
                                onChange={handleSelection}
                              />
                              <span>AGE (NEWEST)</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="AGE (LOWEST)"
                                checked={handDrive === "AGE (LOWEST)"}
                                onChange={handleSelection}
                              />
                              <span>AGE (LOWEST)</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="MOST RECENT"
                                checked={handDrive === "MOST RECENT"}
                                onChange={handleSelection}
                              />
                              <span>MOST RECENT</span>
                            </label>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon2} alt="location-icon" />
                          CONDITION
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon3} alt="location-icon" />
                          HAND DRIVE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="options">
                          <label>
                            <input
                              type="radio"
                              value="right"
                              checked={handDrive === "right"}
                              onChange={handleSelection}
                            />
                            <span>RIGHT</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="left"
                              checked={handDrive === "left"}
                              onChange={handleSelection}
                            />
                            <span>LEFT</span>
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon4} alt="location-icon" />
                          MAKE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon5} alt="location-icon" />
                          MILEAGE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon6} alt="location-icon" />
                          YEAR
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon7} alt="location-icon" />
                          YEAR
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon8} alt="location-icon" />
                          TRANSMISSION
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        <div className="car-listing-icon">
                          <img src={socialicon9} alt="location-icon" />
                          DRIVE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="car-listing-right">
                  <div className="car-listing-right-top">
                    <div className="car-listing-right-top-left">
                      <div className="car-listing-right-top-left-text">
                        <h1>27 VECHICLES AVAILABLE</h1>
                        <div className="car-listing-right-top-left-icon">
                          <div
                            className={list ? "icon inactive" : "icon active"}
                            onClick={() => setList(false)}
                          >
                            <img src={socialicon10} alt="location-icon" />
                          </div>
                          <div
                            className={list ? "icon active" : "icon inactive"}
                            onClick={() => setList(true)}
                          >
                            <img src={socialicon11} alt="location-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${list ? "list-row" : "row"}`}>
                      {cars &&
                        cars.map(
                          (cars, index) => (
                            console.log(185, cars.name),
                            (
                              <div
                                className={`${
                                  list
                                    ? "col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 list-vertical"
                                    : "col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12 list-vertical"
                                }`}
                              >
                                <a href="/car-details">
                                  <div className="blog-listing-box">
                                    <div className="blog-listing-img">
                                      <img
                                        src={`${configs.localApiUrl}${cars.image[0]}`}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigate(`/car-details`, {
                                            state: cars,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="blog-listing-text">
                                      <h1>{cars.name}</h1>
                                      <ul>
                                        <li>
                                          <a href="#">
                                            <img
                                              src={milionicon}
                                              alt="arrow-icon"
                                            />
                                            {cars.mileage}
                                            MILES
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <img
                                              src={petrolicon}
                                              alt="arrow-icon"
                                            />
                                            {cars.fuelType}
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <img
                                              src={manualicon}
                                              alt="arrow-icon"
                                            />
                                            {cars.transmission}
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <img
                                              src={bolteg}
                                              alt="arrow-icon"
                                            />
                                            {cars.engine}
                                            1.4
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <img
                                              src={locationicon}
                                              alt="arrow-icon"
                                            />{" "}
                                            OLDHAM
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="grop-btn">
                                        <a href="#" className="btn btn-primary">
                                          £{cars.price}
                                        </a>
                                        <a href="#" className="btn btn-primary">
                                          <img src={plusicon} alt="" />
                                          ADD TO COMPARE
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            )
                          )
                        )}
                      {/* <div
                        className={`${
                          list
                            ? "col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 list-vertical"
                            : "col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12 list-vertical"
                        }`}
                      >
                        <a href="/car-details">
                          <div className="blog-listing-box">
                            <div className="blog-listing-img">
                              <img src={carimg2} alt="" />
                            </div>
                            <div className="blog-listing-text">
                              <h1>RANGE ROVER SPORT</h1>
                              <ul>
                                <li>
                                  <a href="#">
                                    <img src={milionicon} alt="arrow-icon" />{" "}
                                    10,000 MILES
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={petrolicon} alt="arrow-icon" />{" "}
                                    DIESEL
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={manualicon} alt="arrow-icon" />{" "}
                                    AUTO
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={bolteg} alt="arrow-icon" /> 2.0
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={locationicon} alt="arrow-icon" />{" "}
                                    BIRMINGHAM
                                  </a>
                                </li>
                              </ul>
                              <div className="grop-btn">
                                <a href="#" className="btn btn-primary">
                                  £90,000
                                </a>
                                <a href="#" className="btn btn-primary">
                                  <img src={plusicon} alt="" />
                                  ADD TO COMPARE
                                </a>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div
                        className={`${
                          list
                            ? "col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 list-vertical"
                            : "col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12 list-vertical"
                        }`}
                      >
                        <a href="/car-details">
                          <div className="blog-listing-box">
                            <div className="blog-listing-img">
                              <img src={carimg2} alt="" />
                            </div>
                            <div className="blog-listing-text">
                              <h1>RANGE ROVER SPORT</h1>
                              <ul>
                                <li>
                                  <a href="#">
                                    <img src={milionicon} alt="arrow-icon" />{" "}
                                    10,000 MILES
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={petrolicon} alt="arrow-icon" />{" "}
                                    PETROL
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={manualicon} alt="arrow-icon" />{" "}
                                    MANUAL
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={bolteg} alt="arrow-icon" /> 1.4
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={locationicon} alt="arrow-icon" />{" "}
                                    OLDHAM
                                  </a>
                                </li>
                              </ul>
                              <div className="grop-btn">
                                <a href="#" className="btn btn-primary">
                                  £90,000
                                </a>
                                <a href="#" className="btn btn-primary">
                                  <img src={plusicon} alt="" />
                                  ADD TO COMPARE
                                </a>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div
                        className={`${
                          list
                            ? "col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 list-vertical"
                            : "col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12 list-vertical"
                        }`}
                      >
                        <a href="/car-details">
                          <div className="blog-listing-box">
                            <div className="blog-listing-img">
                              <img src={carimg2} alt="" />
                            </div>
                            <div className="blog-listing-text">
                              <h1>RANGE ROVER SPORT</h1>
                              <ul>
                                <li>
                                  <a href="#">
                                    <img src={milionicon} alt="arrow-icon" />{" "}
                                    10,000 MILES
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={petrolicon} alt="arrow-icon" />{" "}
                                    DIESEL
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={manualicon} alt="arrow-icon" />{" "}
                                    AUTO
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={bolteg} alt="arrow-icon" /> 2.0
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img src={locationicon} alt="arrow-icon" />{" "}
                                    BIRMINGHAM
                                  </a>
                                </li>
                              </ul>
                              <div className="grop-btn">
                                <a href="#" className="btn btn-primary">
                                  £90,000
                                </a>
                                <a href="#" className="btn btn-primary">
                                  <img src={plusicon} alt="" />
                                  ADD TO COMPARE
                                </a>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CarListing;
