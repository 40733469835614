import { type } from "@testing-library/user-event/dist/type";
import { homeConstant } from "../_constants/home.constant";
import { homeService } from "../_services/home.service";
import { alert } from "../_utilities/alert";

export const homeAction = {
  getCarsByTenantId,
  getBrandsByTenantId,
};

function getCarsByTenantId() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.GET_CARBYID_REQUEST,
        data: null,
      })
    );
    homeService
      .getCarsByTenantId()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_CARBYID_SUCCESS,
                data: response.Result.cars,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_CARBYID_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.GET_CARBYID_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function getBrandsByTenantId() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.GET_ALLBRANDS_REQUEST,
        data: null,
      })
    );
    homeService
      .getBrandsByTenantId()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_ALLBRANDS_SUCCESS,
                data: response.Result.brands,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_ALLBRANDS_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.GET_ALLBRANDS_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function dispatchFunction(data) {
  // console.log(149, data);
  return {
    type: data.type,
    data: data.data,
  };
}
