import React from 'react';
import './ulits/scss/style.scss';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import AboutHistory from './pages/About/AboutHistory';
import AboutChooseus from './pages/About/AboutChooseus';
import AboutGoogleReviews from './pages/About/AboutGoogleReviews';
import BlogListing from './pages/BlogListing';
import BlogDetails from './pages/BlogListing/BlogDetails';
import Contact from './pages/Contact';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import CarListing from './pages/CarListing';
import CarDetails from './pages/CarListing/CarDetails';
import Finance from './pages/Finance';
import EuropeanInsurance from './pages/European-Insurance';
import { ChildThemeLayout } from './Layout/ChildThemeLayout';


function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Routes>
      <Route path="/*" element={<ChildThemeLayout />} />;
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
