import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const europeanService = {
  european,
};

function european(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(`${configs.localApiUrl}/insurance?tenantId=${configes.domainId}`, requestOptions).then(
    (response) => response.json()
  );
}