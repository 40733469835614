import { type } from "@testing-library/user-event/dist/type";
import { contactConstant } from "../_constants/contact.constants";
import { contactService } from "../_services/contact.service";
import { alert } from "../_utilities/alert";
import { configes } from "../webData";

export const contactAction = {
  contact,
};

function contact(data) {
  console.log(10, data);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: contactConstant.CONTACT_REQUEST,
        data: null,
      })
    );
    contactService
      .contact({ ...data, tenantId: configes.domainId })
      .then(
        (responce) => {
          if (responce.Status === 200) {
            dispatch(
              dispatchFunction({
                type: contactConstant.CONTACT_SUCCESS,
                data: responce.Result.contact,
              })
            );
            alert.success(responce.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: contactConstant.CONTACT_FAILURE,
                data: responce.Message,
              })
            );
            alert.error(responce.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: contactConstant.CONTACT_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function dispatchFunction(data) {
    return {
        type : data.type,
        data : data.data
    }
}