import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "../_store";
import { PersistGate } from "redux-persist/integration/react";
import { Route, Routes } from "react-router";
import "../ulits/scss/style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home";
import About from "../pages/About";
import AboutHistory from "../pages/About/AboutHistory";
import AboutChooseus from "../pages/About/AboutChooseus";
import AboutGoogleReviews from "../pages/About/AboutGoogleReviews";
import BlogListing from "../pages/BlogListing";
import BlogDetails from "../pages/BlogListing/BlogDetails";
import Contact from "../pages/Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarListing/CarDetails";
import Finance from "../pages/Finance";
import EuropeanInsurance from "../pages/European-Insurance";

export function ChildThemeLayout() {
  return (
    <div className="color-theme">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={true}>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about-history" element={<AboutHistory />} />
              <Route path="/about-choose" element={<AboutChooseus />} />
              <Route path="/google-reviews" element={<AboutGoogleReviews />} />
              <Route path="/blog-listing" element={<BlogListing />} />
              <Route path="/blog-details" element={<BlogDetails />} />
              <Route path="/car-listing" element={<CarListing />} />
              <Route path="/car-details" element={<CarDetails />} />
              <Route path="/finance" element={<Finance />} />
              <Route
                path="/european-insurance"
                element={<EuropeanInsurance />}
              />
            </Routes>
            <Footer />
          </Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
}
