import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import aboutcarimg2 from "../../ulits/images/about-car-img2.png";
import homecarimg from "../../ulits/images/about-car-img.png";
import logoicon1 from "../../ulits/images/logo_icon1.png";
import logoicon2 from "../../ulits/images/logo_icon2.png";
import logoicon3 from "../../ulits/images/logo_icon3.png";
import logoicon4 from "../../ulits/images/logo_icon4.png";
import logoicon5 from "../../ulits/images/logo_icon5.png";
import logoicon6 from "../../ulits/images/logo_icon6.png";
import logoicon7 from "../../ulits/images/logo_icon7.png";
import logoicon11 from "../../ulits/images/logo_icon11.png";
import logoicon12 from "../../ulits/images/logo_icon12.png";
import logoicon13 from "../../ulits/images/logo_icon13.png";
import logoicon14 from "../../ulits/images/logo_icon14.png";
import logoicon15 from "../../ulits/images/logo_icon15.png";
import logoicon16 from "../../ulits/images/logo_icon16.png";
import logoicon17 from "../../ulits/images/logo_icon17.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

function AboutHistory() {
  const [loader, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-col-section first">
            <div className="col-text blue-bg order-last">
              <div className="our-courses-box">
                <div className="date-title">
                  <h1>THE HISTORY OF THE LEFT HAND DRIVE CAR SHOWROOM</h1>
                  <p>
                    With access to one of the country’s largest selections of
                    quality left hand drive cars, we are able to offer some of
                    the most attractive used left hand drive cars and some of
                    the most competitive deals available.
                  </p>
                  <button
                    className="primary-btn sm"
                    //   onClick={() => navigate("/")}
                  >
                    EXPLORE
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-image">
              <img src={aboutcarimg2} alt="home-col-1" />
            </div>
          </div>
          <div className="about-list-section second">
            <div className="container">
              <div className="about-item-box">
                <div className="about-list-box">
                  <h1>THE HISTORY OF THE LEFT HAND DRIVE CAR SHOWROOM</h1>
                  <p>
                    The Left Hand Drive Car showroom was established in 2015,
                    today the company is being managed by the second generation
                    of the family who have been passed the knowledge and
                    expertise in the Left Hand Drive Car Market.
                  </p>
                  <p>
                    The Business was originally operating in Paris under the
                    first-generation family members providing the French
                    customers with quality used Left Hand Drive Cars. The Family
                    moved to the UK in early 2000s bringing the expertise and
                    knowledge to provide the same quality service and sourcing
                    Left Hand Drive cars to the UK Left Hand Drive dealerships
                    as well as still maintaining the core business in France.
                  </p>
                  <p>
                    Since 2015 the family saw the growth in the UK Left Hand
                    Drive Market and set up their own showroom in Uxbridge to
                    provide LHD cars directly to customers looking to move
                    abroad in Europe and the African Continent.
                  </p>
                  <p>
                    As of today the business has evolved over the years to
                    become an established Left Hand Drive dealership in the UK.
                    The company has always remained a single site independent
                    dealership whilst still maintaining a core business in
                    France. This has allowed the business to source the best car
                    offerings for its customers with unsurpassed level of
                    service.
                  </p>
                </div>
                <div className="about-list-img">
                  <img src={homecarimg} alt="about-col-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="brands-stock text-center">
            <div className="head-title">
              <h2>BRANDS WE WORK WITH</h2>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={1000}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide>
                  <img src={logoicon1} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon2} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon3} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon4} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon5} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon6} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon7} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon1} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon2} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon3} alt="Slide 1" />
                </SwiperSlide>
              </Swiper>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={1300}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide>
                  <img src={logoicon11} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon12} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon13} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon14} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon15} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon16} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon17} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon11} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon12} alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={logoicon13} alt="Slide 1" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AboutHistory;
