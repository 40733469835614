import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import homecarimg from "../../ulits/images/about-car-img.png";
import { useNavigate } from "react-router-dom";

function About() {
  const [loader, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-col-section first">
            <div className="col-text blue-bg order-last">
              <div className="our-courses-box">
                <div className="date-title">
                  <h1>WELCOME TO THE LEFT HAND DRIVE CAR SHOWROOM LTD</h1>
                  <p>
                    With access to one of the country’s largest selections of
                    quality left hand drive cars, we are able to offer some of
                    the most attractive used left hand drive cars and some of
                    the most competitive deals available.
                  </p>
                  <button
                    className="primary-btn sm"
                    //   onClick={() => navigate("/")}
                  >
                    EXPLORE
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-image">
              <img src={homecarimg} alt="home-col-1" />
            </div>
          </div>
          <div className="about-list-section second">
            <div className="container">
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-box">
                  <h1>LEFT HAND DRIVE CARS FOR MOVING ABROAD</h1>
                  <p>
                    We regularly buy and sell left hand drive vehicles, with
                    customers throughout the UK and mainland Europe. If you are
                    looking to buy a left hand drive car before you move abroad,
                    or simply to buy a higher spec left hand drive vehicle, give
                    us a ring and we’ll see how we can help or advise you. We
                    have particular expertise in exporting cars to and from
                    Portugal, France and Spain, and can help you with all of the
                    formalities.
                  </p>
                  <h1>CONVENIENT LOCATION</h1>
                  <p>
                    Located in comfortable showrooms near Heathrow Airport in
                    West London (adjacent to the M25, M4 and M40, and just 20
                    minutes from the M1-J21), we offer all of the benefits of a
                    long-established, family-run business – one which takes
                    pride in offering the highest levels of customer service
                    with every L.H.D car we sell.
                  </p>
                </div>
                <div className="about-list-img">
                  <img src={homecarimg} alt="about-col-2" />
                </div>
              </div>
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-img">
                  <img src={homecarimg} alt="about-col-2" />
                </div>
                <div className="about-list-box">
                  <h1>CAN I JUST BUY MY LEFT HAND DRIVE CAR FROM EUROPE?</h1>
                  <p>
                    {" "}
                    Legally, you can buy a left hand drive car from Europe on
                    your own, but there are several possible pitfalls you should
                    consider:
                  </p>
                  <p>
                    You will need to find a dealership and talk to them
                    directly: you may not know if they’re a reputable company or
                    speak your language properly. The language barrier can cause
                    a lot of additional problems. It is not uncommon for people
                    to end up paying more for your left hand drive car, as
                    European dealers are more expensive than the UK!
                  </p>
                </div>
              </div>
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-box">
                  <h1>ADDITIONAL PROBLEMS MAY ARISE FROM:</h1>
                  <h4>
                    • Having to deal with unfamiliar documentation often in a
                    language you’re not familiar with.
                  </h4>
                  <h4>
                    • You will have to arrange your own left hand drive
                    insurance.
                  </h4>
                  <h4>
                    • You’ll have to make your own transport arrangements.
                  </h4>
                  <p>
                    If something goes wrong, you’ll only be protected by the
                    prevailing contract laws in whichever country the dealer is
                    located, and often these can be unfamiliar.
                  </p>
                  <p>
                    {" "}
                    Remember, when you pay for your left hand drive car in your
                    native currency, you will also save on exchange rates.
                  </p>
                </div>
                <div className="about-list-img">
                  <img src={homecarimg} alt="about-col-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default About;
