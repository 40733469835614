import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const blogDetailService = {
  getBlogDetail,
};

// function getBlogDetail() {
//   const extraHeaders = {
//     "Content-Type": "application/json",
//   };
//   const requestOptions = commonFunctions.getRequestOptions("GET", extraHeaders);
//   return fetch(`${config.localApiUrl}/getBlog/${configs.domainId}`, requestOptions).then(
//     (response) => response.json()
//   );
// }


function getBlogDetail() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getBlog/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}
