import { blogDetailConstant } from "../_constants/blogDetail.constant";
import { alert } from "../_utilities/alert";
import { blogDetailService } from "../_services/blogDetail.service";

export const blogAction = {
  getBlogDetail,
};


function getBlogDetail() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: blogDetailConstant.GET_BLOG_DETAIL_REQUEST,
        data: null,
      })
    );
    blogDetailService
    .getBlogDetail()
    .then(
      (response) => {
        if (response.Status === 200) {
            console.log(9, response);
            dispatch(
              dispatchFunction({
                type: blogDetailConstant.GET_BLOG_DETAIL_SUCCESS,
                data: response.Result.blog,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: blogDetailConstant.GET_BLOG_DETAIL_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: blogDetailConstant.GET_BLOG_DETAIL_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
