import { homeConstant } from "../_constants/home.constant";
import { status } from "../_constants/commonLhd";

let initialState = {};
console.log(initialState);

export function home(state = initialState, action) {
  switch (action.type) {
    case homeConstant.GET_CARBYID_REQUEST:
      return {
        ...state,
        car_status: status.IN_PROGRESS,
        car_data: null,
      };
    case homeConstant.GET_CARBYID_SUCCESS:
      return {
        ...state,
        car_status: status.SUCCESS,
        car_data: action.data,
      };
    case homeConstant.GET_CARBYID_FAILURE:
      return {
        ...state,
        car_status: status.FAILURE,
        car_data: action.data,
      };

    case homeConstant.GET_ALLBRANDS_REQUEST:
      return {
        ...state,
        brands_status: status.IN_PROGRESS,
        brands_data: null,
      };
    case homeConstant.GET_ALLBRANDS_SUCCESS:
      return {
        ...state,
        brands_status: status.SUCCESS,
        brands_data: action.data,
      };
    case homeConstant.GET_ALLBRANDS_FAILURE:
      return {
        ...state,
        brands_status: status.FAILURE,
        brands_data: action.data,
      };

    default:
      return state;
  }
}
