import React, { useState, useEffect } from "react";
import headerlogo from "../ulits/images/header-logo.png";
import gmailicon from "../ulits/images/gmail-icon.svg";
import callicon from "../ulits/images/call-icon.svg";
import { Link } from "react-router-dom";
import arrowwhite from "../ulits/images/arrow_icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { configAction } from "../_actions/config.action";
import { status } from "../_constants/commonLhd";
import { configs } from "../config";

function Header() {
  const [activeLink, setActiveLink] = useState("BUY");
  const [activeIndex, setActiveIndex] = useState("FINANCE");
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState({});

  const handleClick = (index) => {
    setActiveLink(index);
  };
  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const Dropdown = () => {
    setIsOpen(!isOpen);
  };

  const ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch();
  const configData = useSelector((state) => state.config);
  
  useEffect(() => {
    dispatch(configAction.getConfigByTenantId());
  }, []);

  useEffect(() => {
    if (configData && configData.config_status === status.SUCCESS) {
      setConfig(configData.config_data);
    }
  }, [configData.config_data]);

  return (
    <div className="site-header">
      <div className="header-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-10">
              <div className="header-top-left">
                <Link to="/">
                  <img
                    src={`${configs.localApiUrl}${config.headerLogo}`}
                    alt="header-logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-2">
              <div className="header-top-center">
                <ul>
                  <li>
                    <Link
                      to="#"
                      className={activeLink === "BUY" ? "active" : ""}
                      onClick={() => handleClick("BUY")}
                    >
                      BUY
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={activeLink === "SELL" ? "active" : ""}
                      onClick={() => handleClick("SELL")}
                    >
                      SELL
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={activeLink === "MORE" ? "active" : ""}
                      onClick={() => handleClick("MORE")}
                    >
                      MORE
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sidebar">
                <button
                  className={`menu-button ${isOpen == true ? "open" : ""}`}
                  onClick={ToggleSidebar}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <nav className={`menu ${isOpen == true ? "open" : ""}`}>
                  <div className="sd-header">
                    <div className="menu-close" onClick={ToggleSidebar}>
                      <img src={arrowwhite} alt="Close Menu" />
                    </div>
                  </div>
                  <div className="hide-desk">
                    <div className="meno-menu">
                      <ul className="mobo-menu">
                        <li>
                          <Link
                            to="#"
                            className={activeLink === "BUY" ? "active" : ""}
                            onClick={() => handleClick("BUY")}
                          >
                            BUY
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={activeLink === "SELL" ? "active" : ""}
                            onClick={() => handleClick("SELL")}
                          >
                            SELL
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={activeLink === "MORE" ? "active" : ""}
                            onClick={() => handleClick("MORE")}
                          >
                            MORE
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-0">
              <div className="header-top-right">
                <ul>
                  <li>
                    <a href="mailto:lhdcarshowroom@gmail.com">
                      <img src={gmailicon} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="tel:0161 620 2150">
                      <img src={callicon} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="header-bottom-center">
                <ul>
                  <li onClick={Dropdown}>
                    <Link
                      to="/european-hpi"
                      className={
                        activeIndex === "EUROPEAN HPI CHECKS" ? "active" : ""
                      }
                      onClick={() => handleItemClick("EUROPEAN HPI CHECKS")}
                    >
                      EUROPEAN HPI CHECKS
                    </Link>
                    <div className={`dropdown-menu ${isOpen ? "open" : ""}`}>
                      <ul>
                        <li>
                          <a href="#">EUROPEAN HPI CHECKS</a>
                        </li>
                        <li>
                          <a href="#">ORDER A SPANISH HPI CHECK</a>
                        </li>
                        <li>
                          <a href="#">ORDER A FRENCH HPI CHECK</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    className={
                      activeIndex === "EUROPEAN INSURANCE" ? "active" : ""
                    }
                    onClick={() => handleItemClick("EUROPEAN INSURANCE")}
                  >
                    <Link to="/european-insurance">EUROPEAN INSURANCE</Link>
                  </li>
                  <li
                    className={activeIndex === "FINANCE" ? "active" : ""}
                    onClick={() => handleItemClick("FINANCE")}
                  >
                    <Link to="/finance">FINANCE</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
