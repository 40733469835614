import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import financeimg from "../../ulits/images/finance-img.png";
import Accordion from "react-bootstrap/Accordion";
import scuklogo from "../../ulits/images/scuk-logo.png";
import blackhorselogo from "../../ulits/images/black-horse-logo.png";
import cbmotorfinance2024 from "../../ulits/images/cb-motor-finance-2024.png";
import zutoowler from "../../ulits/images/zuto_owler.png";
import MotoNovoFinanceLogo from "../../ulits/images/MotoNovo-Finance-Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { financeAction } from "../../_actions/finance.action";
import { status } from "../../_constants/commonLhd";

function Finance() {
  const [loader, setLoader] = useState(true);
  const [selectedOption, setSelectedOption] = useState("MARITIAL STATUS");
  // const dispatch = useDispatch();
  // const financeData = useSelector((state) => state.finance);
  // const [formData, setFormData] = useState({ name: "" });

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // useEffect(() => {
  //   if (financeData && financeData.finance_status === status.SUCCESS) {
  //     setFormData({ name: "" });
  //     console.log("financeData", financeData);
  //   }
  // }, [financeData]);

  // const handleClick = () => {
  //   console.log("Submitting formData:", formData);
  //   dispatch(financeAction.finance(formData));
  // };

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="finance-section">
            <div className="container">
              <div className="finance-img">
                <img src={financeimg} alt="" />
              </div>
              <div className="finance-title">
                <h1>FLEXIBLE FINANCE DEALS TO SUIT ALL BUDGETS</h1>
                <p>
                  As one of the leading left hand drive cars showrooms in the UK
                  we ensure that when it comes to the purchase of your left hand
                  drive car, we keep our customers fully informed of the various
                  finance options available. We work with a number of finance
                  companies allowing us to have access to some of the best
                  finance packages on the market.
                </p>
              </div>
              <div className="finance-list">
                <h1>APPLY FOR FINANCE</h1>
                <h3>PERSONAL DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        // value={formData.name}
                        placeholder="NAME"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <input
                          type="text"
                          placeholder="PHONE"
                          className="form-control"
                        />
                        <div className="dropdown-container">
                          <select
                            id="ownership-dropdown"
                            className="custom-select"
                            value={selectedOption}
                            onChange={handleChange}
                          >
                            <option value="Ownership">Ownership</option>
                            <option value="Owner">Owner</option>
                            <option value="Tennant">Tennant</option>
                          </select>
                        </div>
                        <select
                          id="form-control"
                          value={selectedOption}
                          onChange={handleChange}
                          style={{
                            padding: "10px",
                            border: "1px solid #77868d",
                            borderRadius: "5px",
                            width: "100%",
                            marginBottom: "33px",
                            height: "50px",
                            color: "#77868d",
                            fontFamily: "com-DIN_2014_Demi",
                            fontSize: "12px",
                            outline: "none",
                          }}
                        >
                          <option value="TIME AT ADDRESS">
                            TIME AT ADDRESS
                          </option>
                          <option value="Owner">OWNER</option>
                          <option value="Tennant">TENNANT</option>
                          <option value="Parents">PARENTS</option>
                        </select>
                        <input
                          type="text"
                          placeholder="PREVIOUS ADDRESS"
                          className="form-control"
                        />
                        <select
                          id="form-control"
                          value={selectedOption}
                          onChange={handleChange}
                          style={{
                            padding: "10px",
                            border: "1px solid #77868d",
                            borderRadius: "5px",
                            width: "100%",
                            marginBottom: "33px",
                            height: "50px",
                            color: "#77868d",
                            fontFamily: "com-DIN_2014_Demi",
                            fontSize: "12px",
                            outline: "none",
                          }}
                        >
                          <option value="OWNERSHIP">OWNERSHIP</option>
                          <option value="Owner">OWNER</option>
                          <option value="Tennant">TENNANT</option>
                          <option value="Parents">PARENTS</option>
                        </select>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="EMAIL"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="DOB"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="ADDRESS"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="OWNERSHIP"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="TIME AT PREVIOUS ADDRESS"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <h3>VECHICLE DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="MAKE"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="PURCHASE PRICE"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="PREFERED TERM"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="MODEL"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="FINANCE REQUIRED"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <h3>EMPLOYMENT DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="JOB TITLE"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="EMPLOYMENT STATUS"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="EMPLOYERS NAME"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="EMPLOYERS ADDRESS"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <h3>PREVIOUS EMPLOYMENT DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="EMPLOYERS PHONE NUMBER"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="JOB TITLE"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="EMPLOYMENT STATUS"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="LENGTH OF EMPLOYMENT"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <input
                          type="text"
                          placeholder="LENGTH OF EMPLOYMENT"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="EMPLOYERS NAME"
                          className="form-control"
                        />
                        <input
                          type="text"
                          placeholder="EMPLOYERS ADDRESS"
                          className="form-control"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <button className="btn primary-btn">
                  SEND MESSAGE
                </button>
              </div>
            </div>
            <div className="stm-accordion-single-unit right-hand-drive">
              <div className="container">
                <div className="stm-accordion-title">
                  <h3>FREQUENTLY ASKED QUESTIONS</h3>
                </div>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>LOREM IPSUM DOLOR</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis sapien dui, venenatis in tortor eget, finibus
                      pulvinar enim. Donec consequat elit at condimentum
                      viverra. Donec cursus metus lorem, a suscipit leo egestas
                      sed. Donec rhoncus leo eu maximus interdum. Nam molestie
                      lacus a porta mattis. Suspendisse id sapien purus. Etiam
                      quis sollicitudin nulla. Duis lobortis lorem id vestibulum
                      volutpat. Cras mollis sed tortor quis tincidunt. Cras sit
                      amet lacus ut ligula tincidunt placerat eget sit amet
                      sapien. Fusce id lectus nec lectus mattis feugiat. Vivamus
                      rhoncus at arcu at aliquet. Pellentesque habitant morbi
                      tristique senectus et netus et malesuada fames ac turpis
                      egestas.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>LOREM IPSUM DOLOR</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis sapien dui, venenatis in tortor eget, finibus
                      pulvinar enim. Donec consequat elit at condimentum
                      viverra. Donec cursus metus lorem, a suscipit leo egestas
                      sed. Donec rhoncus leo eu maximus interdum. Nam molestie
                      lacus a porta mattis. Suspendisse id sapien purus. Etiam
                      quis sollicitudin nulla. Duis lobortis lorem id vestibulum
                      volutpat. Cras mollis sed tortor quis tincidunt. Cras sit
                      amet lacus ut ligula tincidunt placerat eget sit amet
                      sapien. Fusce id lectus nec lectus mattis feugiat. Vivamus
                      rhoncus at arcu at aliquet. Pellentesque habitant morbi
                      tristique senectus et netus et malesuada fames ac turpis
                      egestas.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>LOREM IPSUM DOLOR</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis sapien dui, venenatis in tortor eget, finibus
                      pulvinar enim. Donec consequat elit at condimentum
                      viverra. Donec cursus metus lorem, a suscipit leo egestas
                      sed. Donec rhoncus leo eu maximus interdum. Nam molestie
                      lacus a porta mattis. Suspendisse id sapien purus. Etiam
                      quis sollicitudin nulla. Duis lobortis lorem id vestibulum
                      volutpat. Cras mollis sed tortor quis tincidunt. Cras sit
                      amet lacus ut ligula tincidunt placerat eget sit amet
                      sapien. Fusce id lectus nec lectus mattis feugiat. Vivamus
                      rhoncus at arcu at aliquet. Pellentesque habitant morbi
                      tristique senectus et netus et malesuada fames ac turpis
                      egestas.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>LOREM IPSUM DOLOR</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis sapien dui, venenatis in tortor eget, finibus
                      pulvinar enim. Donec consequat elit at condimentum
                      viverra. Donec cursus metus lorem, a suscipit leo egestas
                      sed. Donec rhoncus leo eu maximus interdum. Nam molestie
                      lacus a porta mattis. Suspendisse id sapien purus. Etiam
                      quis sollicitudin nulla. Duis lobortis lorem id vestibulum
                      volutpat. Cras mollis sed tortor quis tincidunt. Cras sit
                      amet lacus ut ligula tincidunt placerat eget sit amet
                      sapien. Fusce id lectus nec lectus mattis feugiat. Vivamus
                      rhoncus at arcu at aliquet. Pellentesque habitant morbi
                      tristique senectus et netus et malesuada fames ac turpis
                      egestas.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>LOREM IPSUM DOLOR</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis sapien dui, venenatis in tortor eget, finibus
                      pulvinar enim. Donec consequat elit at condimentum
                      viverra. Donec cursus metus lorem, a suscipit leo egestas
                      sed. Donec rhoncus leo eu maximus interdum. Nam molestie
                      lacus a porta mattis. Suspendisse id sapien purus. Etiam
                      quis sollicitudin nulla. Duis lobortis lorem id vestibulum
                      volutpat. Cras mollis sed tortor quis tincidunt. Cras sit
                      amet lacus ut ligula tincidunt placerat eget sit amet
                      sapien. Fusce id lectus nec lectus mattis feugiat. Vivamus
                      rhoncus at arcu at aliquet. Pellentesque habitant morbi
                      tristique senectus et netus et malesuada fames ac turpis
                      egestas.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>LOREM IPSUM DOLOR</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis sapien dui, venenatis in tortor eget, finibus
                      pulvinar enim. Donec consequat elit at condimentum
                      viverra. Donec cursus metus lorem, a suscipit leo egestas
                      sed. Donec rhoncus leo eu maximus interdum. Nam molestie
                      lacus a porta mattis. Suspendisse id sapien purus. Etiam
                      quis sollicitudin nulla. Duis lobortis lorem id vestibulum
                      volutpat. Cras mollis sed tortor quis tincidunt. Cras sit
                      amet lacus ut ligula tincidunt placerat eget sit amet
                      sapien. Fusce id lectus nec lectus mattis feugiat. Vivamus
                      rhoncus at arcu at aliquet. Pellentesque habitant morbi
                      tristique senectus et netus et malesuada fames ac turpis
                      egestas.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="wpb_wrapper">
              <div className="container">
                <ul>
                  <li>
                    <img src={scuklogo} alt="" />
                  </li>
                  <li>
                    <img src={cbmotorfinance2024} alt="" />
                  </li>
                  <li>
                    <img src={blackhorselogo} alt="" />
                  </li>
                  <li>
                    <img src={zutoowler} alt="" />
                  </li>
                  <li>
                    <img src={MotoNovoFinanceLogo} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Finance;
