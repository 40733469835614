import { combineReducers } from "redux";
import { home } from "./home.reducer";
import { config } from "./config.reducer";
import { blog } from "./blogDetail.reducer";
import { european } from "./european.reducer";
import { finance } from "./finance.reducer";
import { contact } from "./contact.reducers";

const appReducers = combineReducers({
  home,
  config,
  blog,
  european,
  finance,
  contact,
});

const rootreducer = (state, action) => {
  return appReducers(state, action);
};

export default rootreducer;
