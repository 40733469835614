import React, { useState, useEffect } from "react";
import heart from "../ulits/images/heart.svg";
import footerlogo from "../ulits/images/footer-logo.png";
import locationicon from "../ulits/images/location-icon.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { configAction } from "../_actions/config.action";
import { status } from "../_constants/commonLhd";
import { configs } from "../config";

function Footer() {
  const [config, setConfig] = useState({});

  const dispatch = useDispatch();
  const configData = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(configAction.getConfigByTenantId());
  }, []);

  useEffect(() => {
    if (configData && configData.config_status === status.SUCCESS) {
      setConfig(configData.config_data);
    }
  }, [configData.config_data]);

  return (
    <div className="site-footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-logo text-center">
            <Link to="/">
              <img
                src={`${configs.localApiUrl}${config.footerLogo}`}
                alt="footer-logo"
              />
            </Link>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-6">
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <img src={locationicon} alt="location-icon" /> 55 COWLEY
                    ROAD
                  </a>
                </li>
                <li>
                  <a href="#">UXBRIDGE</a>
                </li>
                <li>
                  <a href="#">MIDDLESEX</a>
                </li>
                <li>
                  <a href="#">UB8 2AE"</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-6">
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <img src={locationicon} alt="location-icon" /> 55 COWLEY
                    ROAD
                  </a>
                </li>
                <li>
                  <a href="#">UXBRIDGE</a>
                </li>
                <li>
                  <a href="#">MIDDLESEX</a>
                </li>
                <li>
                  <a href="#">UB8 2AE</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-6">
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <img src={locationicon} alt="location-icon" /> 55 COWLEY
                    ROAD
                  </a>
                </li>
                <li>
                  <a href="#">UXBRIDGE</a>
                </li>
                <li>
                  <a href="#">MIDDLESEX</a>
                </li>
                <li>
                  <a href="#">UB8 2AE</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-6">
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <img src={locationicon} alt="location-icon" /> 55 COWLEY
                    ROAD
                  </a>
                </li>
                <li>
                  <a href="#">UXBRIDGE</a>
                </li>
                <li>
                  <a href="#">MIDDLESEX</a>
                </li>
                <li>
                  <a href="#">UB8 2AE</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 col-12">
              <ul className="list-unstyled">
                <li>
                  <a href="#">FREE PHONE: 0800 689 1785</a>
                </li>
                <li>
                  <a href="#">LANDLINE: 0044 (0) 1895 347 410</a>
                </li>
                <li>
                  <a href="#">MOBILE: 0044 (0) 7983 866 333</a>
                </li>
                <li>
                  <a href="#">EMAIL: lhdcarshowroom@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <ul className="list-inline">
                <li>
                  <a href="#">Terms of use</a>
                </li>
                <li>
                  <a href="#">Privacy</a>
                </li>
              </ul>
              <p>
                © 2024 Copyrights The Left Hand Drive Car Showroom Limited. All
                Rights Reserved.
              </p>
              <div className="loyalty-text">
                made with
                <em>
                  <img src={heart} alt="heart" />
                </em>
                by
                <a href="https://softorigin.co.uk/" target="_blank">
                  {" "}
                  Soft Origin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
