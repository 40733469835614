import { status } from "../_constants/commonLhd";
import { financeConstant } from "../_constants/finance.constant";

let Initialstate = {};

export function finance(state = Initialstate, action) {
  switch (action.type) {
    case financeConstant.GET_FINANCE_REQUEST:
      return {
        ...state,
        finance_status: status.IN_PROGRESS,
        finance_data: null,
      };
    case financeConstant.GET_FINANCE_SUCCESS:
      return {
        ...state,
        finance_status: status.SUCCESS,
        finance_data: action.data,
      };
    case financeConstant.GET_FINANCE_FAILURE:
      return {
        ...state,
        finance_status: status.FAILURE,
        finance_data: action.data,
      };
    default:
      return state;
  }
}
