import { blogDetailConstant } from "../_constants/blogDetail.constant";
import { status } from "../_constants/commonLhd";

let InitialState = {};
console.log(InitialState);

export function blog(state = InitialState, action) {
  switch (action.type) {
    case blogDetailConstant.GET_BLOG_DETAIL_REQUEST:
      return {
        ...state,
        blog_status: status.IN_PROGRESS,
        blog_data: null,
      };
    case blogDetailConstant.GET_BLOG_DETAIL_SUCCESS:
      return {
        ...state,
        blog_status: status.SUCCESS,
        blog_data: action.data,
      };
    case blogDetailConstant.GET_BLOG_DETAIL_FAILURE:
      return {
        ...state,
        blog_status: status.FAILURE,
        blog_data: action.data,
      };

    default:
      console.log(222, state);
      return state;
  }
}
