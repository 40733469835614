import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import homecarimg from "../../ulits/images/home-car-img.png";
import bgimage from "../../ulits/images/bg-image.png";
import carimg1 from "../../ulits/images/car_img1.png";
import milionicon from "../../ulits/images/milion_icon.svg";
import petrolicon from "../../ulits/images/petrol_icon.svg";
import manualicon from "../../ulits/images/manual_icon.svg";
import tabicon1 from "../../ulits/images/tab_icon1.svg";
import tabicon2 from "../../ulits/images/tab_icon2.svg";
import tabicon3 from "../../ulits/images/tab_icon3.svg";
import tabicon4 from "../../ulits/images/tab_icon4.svg";
import tabimg1 from "../../ulits/images/tab_img1.png";
import tabimg2 from "../../ulits/images/tab_img2.png";
import tabimg3 from "../../ulits/images/tab_img3.png";
import tabimg4 from "../../ulits/images/tab_img4.png";
import googlepay from "../../ulits/images/google-pay.svg";
import staricon from "../../ulits/images/star-img.svg";
import carimg2 from "../../ulits/images/car_img2.png";
import carimg3 from "../../ulits/images/car_img3.png";
import facebookicon from "../../ulits/images/Facebook_icon.svg";
import tiktokicon from "../../ulits/images/tiktok_icon.svg";
import Instagramicon from "../../ulits/images/Instagram_icon.svg";
import Snapchaticon from "../../ulits/images/Snapchat_icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { homeAction } from "../../_actions";
import { configAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";

function Home() {
  const [loader, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [cars, setCars] = useState([]);
  const [brands, setBrands] = useState([]);
  const [config, setConfig] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const homeData = useSelector((state) => state.home);
  const configData = useSelector((state) => state.config);
  console.log(56, homeData);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
    dispatch(homeAction.getCarsByTenantId());
  }, []);

  useEffect(() => {
    dispatch(configAction.getConfigByTenantId());
  }, []);

  useEffect(() => {
    if (configData && configData.config_status === status.SUCCESS) {
      console.log("111", configData);
      setConfig(configData.config_data);
    }
  }, [configData.config_data]);

  useEffect(() => {
    if (homeData && homeData.car_status === status.SUCCESS) {
      console.log("111", homeData);
      setCars(homeData.car_data);
    }
  }, [homeData.car_data]);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      console.log("111", homeData);
      setBrands(homeData.brands_data);
    }
  }, [homeData.brands_data]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="home-col-section first">
            <div className="col-text blue-bg order-last">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide perSlide>
                  <div className="our-courses-box">
                    <div className="date-inner-title">
                      <div className="date-title">
                        <h1>LEAD BY EXAMPLE</h1>
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Convallis dictum egestas pellentesque parturient
                          habitant urna cubilia.
                        </p>
                        <button
                          className="primary-btn sm"
                          onClick={() => navigate("/car-listing")}
                        >
                          EXPLORE
                          <div className="arrow-icon"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="our-courses-box">
                    <div className="date-inner-title">
                      <div className="date-title">
                        <h1>LEAD BY EXAMPLE</h1>
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Convallis dictum egestas pellentesque parturient
                          habitant urna cubilia.
                        </p>
                        <button
                          className="primary-btn sm"
                          //   onClick={() => navigate("/")}
                        >
                          EXPLORE
                          <div className="arrow-icon"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="our-courses-box">
                    <div className="date-inner-title">
                      <div className="date-title">
                        <h1>LEAD BY EXAMPLE</h1>
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Convallis dictum egestas pellentesque parturient
                          habitant urna cubilia.
                        </p>
                        <button
                          className="primary-btn sm"
                          //   onClick={() => navigate("/")}
                        >
                          EXPLORE
                          <div className="arrow-icon"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-image">
              <img src={homecarimg} alt="home-col-1" />
            </div>
          </div>
          <div className="home-new-section second">
            <div className="col-image">
              <img src={bgimage} alt="home-col-1" />
            </div>
            <div className="date-inner-title">
              <div className="container">
                <div className="date-title">
                  <h1>NEW IN</h1>
                </div>
                <div className="box-wrapper row">
                  {cars &&
                    cars.map(
                      (cars, index) => (
                        console.log(185, cars.name),
                        (
                          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="box">
                              <img
                                src={`${configs.localApiUrl}${cars.image[0]}`}
                                alt="home-col-1"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/car-details`, { state: cars })
                                }
                              />
                              <div className="box-content-inner">
                                <div className="box-content">
                                  <h1>{cars.name}</h1>
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={milionicon}
                                          alt="arrow-icon"
                                        />
                                        {cars.mileage}
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={petrolicon}
                                          alt="arrow-icon"
                                        />
                                        {cars.fuelType}
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <img
                                          src={manualicon}
                                          alt="arrow-icon"
                                        />
                                        {cars.transmission}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <button className="primary-btn sm">
                                  £{cars.price}
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    )}
                </div>
              </div>
              {/* <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      RANGE ROVER SPORT
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
              </div> */}
              {/* <div className="box-wrapper">
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
              </div>
              <div className="box-wrapper">
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
                <div className="box">
                  <img src={carimg1} alt="home-col-1" />
                  <div className="box-content-inner">
                    <div className="box-content">
                      <h1>RANGE ROVER SPORT</h1>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={milionicon} alt="arrow-icon" /> 10,000
                            MILES
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={petrolicon} alt="arrow-icon" /> PETROL
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={manualicon} alt="arrow-icon" /> MANUAL
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button className="primary-btn sm">£90,000</button>
                  </div>
                </div>
              </div> */}
              <button className="primary-btn-list">FULL STOCK LIST</button>
            </div>
          </div>
          <div className="home-choose-us-section">
            <div className="container">
              <div className="heading-title">
                <h1>WHY CHOOSE US</h1>
              </div>
              <div className="choose-us-box">
                <div className="choose-us-wrapper">
                  <div className="choose-us-box">
                    <div className="choose-us-content">
                      <img src={tabicon1} alt="tab-icon" />
                      <h2>FINANCING MADE EASY</h2>
                      <p>
                        We have a variety of finance products to help you buy
                        your new vehicle. We work with a number of finance
                        companies to offer you the best deals on the market.
                      </p>
                    </div>
                    <div className="choose-us-icon">
                      <img src={tabimg1} alt="car-icon" />
                    </div>
                  </div>
                  <div className="choose-us-box">
                    <div className="choose-us-content">
                      <img src={tabicon2} alt="tab-icon" />
                      <h2>WIDE RANGE OF BRANDS</h2>
                      <p>
                        We have a variety of finance products to help you buy
                        your new vehicle. We work with a number of finance
                        companies to offer you the best deals on the market.
                      </p>
                    </div>
                    <div className="choose-us-icon">
                      <img src={tabimg2} alt="car-icon" />
                    </div>
                  </div>
                </div>
                <div className="choose-us-wrapper">
                  <div className="choose-us-box">
                    <div className="choose-us-content">
                      <img src={tabicon3} alt="tab-icon" />
                      <h2>FINANCING MADE EASY</h2>
                      <p>
                        We have a variety of finance products to help you buy
                        your new vehicle. We work with a number of finance
                        companies to offer you the best deals on the market.
                      </p>
                    </div>
                    <div className="choose-us-icon">
                      <img src={tabimg3} alt="car-icon" />
                    </div>
                  </div>
                  <div className="choose-us-box">
                    <div className="choose-us-content">
                      <img src={tabicon4} alt="tab-icon" />
                      <h2>WIDE RANGE OF BRANDS</h2>
                      <p>
                        We have a variety of finance products to help you buy
                        your new vehicle. We work with a number of finance
                        companies to offer you the best deals on the market.
                      </p>
                    </div>
                    <div className="choose-us-icon">
                      <img src={tabimg4} alt="car-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="google-review-section">
            <div className="container">
              <div className="heading-title">
                <h1>GOOGLE REVIEWS</h1>
              </div>
              <div className="google-review-wrapper-inner first-box">
                <div className="google-content-inner">
                  <img
                    src={googlepay}
                    alt="google-img"
                    className="google-img"
                  />
                  <p>5 stars</p>
                  <img src={staricon} alt="arrow-icon" className="star-icon" />
                  <p>234 reviews</p>
                </div>
              </div>
              <div className="google-review-wrapper">
                <div className="google-review-box">
                  <div className="google-review-img">
                    <img
                      src={staricon}
                      alt="arrow-icon"
                      className="google-img"
                    />
                    <img
                      src={googlepay}
                      alt="google-img"
                      className="star-icon"
                    />
                  </div>
                  <div className="google-review-content">
                    <h2>1 month ago</h2>
                    <p>
                      Lorem ipsum odor amet, consectetuer adipiscing elit. Justo
                      blandit sit nunc; curae enim lectus! Elit mi auctor duis
                      ligula magnis. Congue lectus rhoncus odio venenatis sem.
                      Quam sollicitudin potenti turpis torquent semper.
                    </p>
                    <h2>John Smith</h2>
                  </div>
                </div>
                <div className="google-review-box">
                  <div className="google-review-img">
                    <img
                      src={staricon}
                      alt="arrow-icon"
                      className="google-img"
                    />
                    <img
                      src={googlepay}
                      alt="google-img"
                      className="star-icon"
                    />
                  </div>
                  <div className="google-review-content">
                    <h2>1 month ago</h2>
                    <p>
                      Lorem ipsum odor amet, consectetuer adipiscing elit. Justo
                      blandit sit nunc; curae enim lectus! Elit mi auctor duis
                      ligula magnis. Congue lectus rhoncus odio venenatis sem.
                      Quam sollicitudin potenti turpis torquent semper.
                    </p>
                    <h2>John Smith</h2>
                  </div>
                </div>
                <div className="google-review-box">
                  <div className="google-review-img">
                    <img
                      src={staricon}
                      alt="arrow-icon"
                      className="google-img"
                    />
                    <img
                      src={googlepay}
                      alt="google-img"
                      className="star-icon"
                    />
                  </div>
                  <div className="google-review-content">
                    <h2>1 month ago</h2>
                    <p>
                      Lorem ipsum odor amet, consectetuer adipiscing elit. Justo
                      blandit sit nunc; curae enim lectus! Elit mi auctor duis
                      ligula magnis. Congue lectus rhoncus odio venenatis sem.
                      Quam sollicitudin potenti turpis torquent semper.
                    </p>
                    <h2>John Smith</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="join-our-section">
            <div className="join-title">
              <div className="container join-title-inner">
                <h1>JOIN OUR NEWSLETTER NOW</h1>
                <form className="join-form">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Enter your email address"
                  />
                  <div className="arrow-icons"></div>
                </form>
              </div>
              <div className="join-img">
                <img src={carimg2} alt="join-img" />
              </div>
            </div>
          </div>
          <div className="follow-us-section">
            <div className="col-image">
              <img src={carimg3} alt="follow-img" />
            </div>
            <div className="col-content">
              <h2>FOLLOW US ON SOCIALS</h2>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      src={`${configs.localApiUrl}${config.tiktokLogo}`}
                      alt="tiktok_icon"
                      onClick={() => window.open(config.Tiktok_Url)}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={`${configs.localApiUrl}${config.snapchatLogo}`}
                      alt="snapchat_icon"
                      onClick={() => window.open(config.Snapchat_Url)}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={`${configs.localApiUrl}${config.instagramLogo}`}
                      alt="instagram_icon"
                      onClick={() => window.open(config.Instagram_Url)}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={`${configs.localApiUrl}${config.fecebookLogo}`}
                      alt="facebook_icon"
                      onClick={() => window.open(config.Facebook_Url)}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="brands-stock text-center">
            <div className="head-title">
              <h2>BRANDS WE WORK WITH</h2>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={1000}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
              >
                {brands.map((brands) => (
                  <SwiperSlide>
                    <img
                      src={`${configs.localApiUrl}${brands.brandImage}`}
                      alt="Slide 1"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={1300}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
              >
                {brands.map((brands) => (
                  <SwiperSlide>
                    <img
                      src={`${configs.localApiUrl}${brands.brandImage}`}
                      alt="Slide 1"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
