export const commonFunctions = {
  getRequestOptions,
  postRequestOptions
};

function getRequestOptions(type, extraHeaders, body) {
  let requestOptions = {
    method: type,
    headers: {
      ...extraHeaders,
    },
  };
  if (body) {
    requestOptions["body"] = body;
  }
  return requestOptions;
}

function postRequestOptions(type, extraHeaders, body) {
  let requestOptions = {
    method: type,
    headers: {
      ...extraHeaders,
    },
  };
  if (body) {
    requestOptions["body"] = body;
  }
  return requestOptions;
}
