import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import homecarimg from "../../ulits/images/about-car-img2.png";
import { Link, useNavigate } from "react-router-dom";
import googlemap from "../../ulits/images/google-map.png";
import locationicon from "../../ulits/images/location-icon.svg";
import callicon from "../../ulits/images/call-icon.svg";
import gmailicon from "../../ulits/images/gmail-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../../_constants/commonLhd";
import { contactAction } from "../../_actions/contact.action";

function Contact() {
  const [loader, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [activeLink, setActiveLink] = useState("UK OFFICE");
  const [credentials, setCredentials] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   message: "",
  // });

  const handleClick = (index) => {
    setActiveLink(index);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const contactData = useSelector((state) => state.Contact);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (contactData && contactData.contact_status === status.SUCCESS) {
      setCredentials({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  }, [contactData]);

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      isValid,
    };

    if (isValidate) {
      const { name, email, phone } = credentials;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone Number is required",
          isValid: false,
        };
        isValid = false;
      } else if (!PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Please enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }
      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(contactAction.contact(credentials));
      navigate("/");
    }
    setCredentials({ name: "", email: "", phone: "", message: "" });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const error = validateCredentials(isValidate);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="contact-us-section">
            <div className="blue-bg">
              <div className="contact-us-left">
                <div className="container">
                  <h2>CONTACT US</h2>
                  {/* {credentials &&(
                    <p style={{ color: "green" }}>
                      Form submitted successfully!
                    </p>
                  )} */}
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={credentials.name}
                        onChange={handleChange}
                        placeholder="NAME"
                        required
                        className="form-control"
                      />
                      {error && error.name.message !== "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {error.name.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={credentials.email}
                        onChange={handleChange}
                        placeholder="EMAIL"
                        // required
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        id="phone"
                        name="phone"
                        value={credentials.phone}
                        onChange={handleChange}
                        placeholder="Phone No"
                        // required
                        className="form-control"
                      />
                    </div>
                    <div className="form-group textarea">
                      <textarea
                        id="message"
                        name="message"
                        value={credentials.message}
                        onChange={handleChange}
                        placeholder="MESSAGE"
                        // required
                      />
                    </div>
                    <button
                      className="primary-btn sm"
                      type="send"
                      title="Send"
                      onClick={handleSubmit}
                    >
                      SEND
                      <div className="arrow-icon"></div>
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-image-right">
                <img src={homecarimg} alt="home-col-1" />
              </div>
            </div>
            <div className="contact-us-right">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="contact-us-right-content">
                      <ul>
                        <li
                          className={activeLink === "UK OFFICE" ? "active" : ""}
                          onClick={() => handleClick("UK OFFICE")}
                        >
                          <Link to="#">UK OFFICE</Link>
                        </li>
                        <li
                          className={
                            activeLink === "SPANISH OFFICE" ? "active" : ""
                          }
                          onClick={() => handleClick("SPANISH OFFICE")}
                        >
                          <Link to="#">SPANISH OFFICE</Link>
                        </li>
                        <li
                          className={
                            activeLink === "FRANCE OFFICE" ? "active" : ""
                          }
                          onClick={() => handleClick("FRANCE OFFICE")}
                        >
                          <Link to="#">FRANCE OFFICE</Link>
                        </li>
                      </ul>
                      <div className="contact-details">
                        <div className="contact-item">
                          <div className="map-img">
                            <img src={googlemap} alt="map-img" />
                          </div>
                          <div className="contact-info">
                            <h2>UK OFFICE</h2>
                            <ul>
                              <li>
                                <a href="#">
                                  <img src={locationicon} alt="" /> ADDRESS
                                  <span>
                                    55 COWLEY ROAD <br></br> UXBRIDGE<br></br>{" "}
                                    MIDDLESEX <br></br> UB8 2AE
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="tel:0161 620 2150">
                                  <img src={callicon} alt="" /> FREE PHONE
                                  <span>0800 689 1785</span>
                                </a>
                              </li>
                              <li>
                                <a href="tel:0161 620 2150">
                                  <img src={callicon} alt="" /> LANDLINE
                                  <span>0800 (0) 1895 347 410</span>
                                </a>
                              </li>
                              <li>
                                <a href="tel:0161 620 2150">
                                  <img src={callicon} alt="" /> MOBILE
                                  <span>0044 (0) 7983 866 333</span>
                                </a>
                              </li>
                              <li>
                                <a href="mailto:lhdcarshowroom@gmail.com">
                                  <img src={gmailicon} alt="" /> EMAIL
                                  <span>lhdcarshowroom@gmail.com</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;
