import { europeanConstant } from "../_constants/european.constant";
import { europeanService } from "../_services/european.service";
import { alert } from "../_utilities/alert";
import { configes } from "../webData";

export const europeanAction = {
  european,
};

function european(data) {
  console.log(9, data);

  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: europeanConstant.european_REQUEST,
        data: null,
      })
    );

    europeanService
      .european({ ...data, tenantId: configes.domainId })
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: europeanConstant.european_SUCCESS,
                data: response,
              })
            );
            alert.success(response.message);
          } else {
            dispatch(
              dispatchFunction({
                type: europeanConstant.european_FAILURE,
                data: null,
              })
            );
            alert.error(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: europeanConstant.european_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
