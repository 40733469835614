import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import insuranceimg from "../../ulits/images/insurance-img.png";
import righticon from "../../ulits/images/right-icon.svg";
import { status } from "../../_constants/commonLhd";
import { europeanAction } from "../../_actions/european.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

function EuropeanInsurance() {
  const [loader, setLoader] = useState(true);

  // const [credentials, setCredentials] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   message: "",
  // });

  const [credentials,setCredentials] = useState("")
  const [isValidate, setIsValidate] = useState(false);
  const europeanData = useSelector((state) => state.european);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };
  

  useEffect(() => {
    if (europeanData && europeanData.european_status === status.SUCCESS) {
      setCredentials({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  }, [europeanData]);

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      isValid,
    };

    if (isValidate) {
      const { name, email, phone } = credentials;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone Number is required",
          isValid: false,
        };
        isValid = false;
      } else if (!PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Please enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }
      retData.isValid = isValid;
      return retData;
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(europeanAction.european(credentials));
      navigate("/");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const error = validateCredentials(isValidate);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="insurance-section">
            <div className="container">
              <div className="finance-img">
                <img src={insuranceimg} alt="" />
              </div>
              <div className="finance-title">
                <h1>EUROPEAN INSURANCE</h1>
              </div>
              <div className="insurance-content">
                <div className="insurance-text">
                  <h3>FULLY COMPREHENSIVE INSURANCE (UK/FRANCE/SPAIN</h3>
                  <p>
                    At the Left hand drive car showroom, we specialise in
                    helping our customers get the best insurance quotes in
                    France or in Spain. We have selected the best car insurance
                    companies in France or Spain with whom we have worked with
                    for the last 5 years. We can arrange car insurance for any
                    type of vehicle from the UK, Spain or France. Every policy
                    is different so let us have a few details below and our team
                    will call you back to discuss your needs.
                  </p>
                </div>
                <div className="insurance-text">
                  <h3>TEMPORARY INSURANCE FOR ANY EUROPEAN COUNTRIES</h3>
                  <p>
                    We able to insure any cars with a temporary basis product.
                    This insurance will help you drive the car on the continent
                    on a third party basis only and will be subject to extra
                    checks.
                  </p>
                </div>
                <div className="insurance-text">
                  <h3>WHAT IS TEMPORARY AUTO INSURANCE?</h3>
                  <p>
                    A temporary car insurance is short-term insurance which is
                    intended for drivers who do not wish to be insured for a
                    whole year due to the car being exported or re registered in
                    a specific country which can’ be insure in UK.
                  </p>
                  <p>
                    Temporary auto insurance generally covers a period of 1 to
                    90 days and can only be purchased by a driver with minimal
                    experience on the road. In most cases, the subscriber must
                    be at least 21 years old and have held a UK or European
                    driving license for at least 2 years.
                  </p>
                </div>
                <div className="insurance-text">
                  <h3>WHEN TO BUY TEMPORARY AUTO INSURANCE</h3>
                  <p>
                    There are several situations in which temporary auto
                    insurance is recommended:
                  </p>
                  <h5>
                    • In the event of a little use on the road to move the car
                    to a storage place or secure place.
                  </h5>
                  <h5>
                    • In the event of travel to a country not covered by the
                    motorist’s current insurance.
                  </h5>
                  <h5>
                    • In the event of importation of a foreign vehicle with
                    temporary registration and while awaiting the final
                    registration certificate.
                  </h5>
                </div>
                <div className="insurance-text">
                  <h3>STEPS TO FOLLOW</h3>
                  <p>
                    In addition to verifying the age and history of the driver
                    who wishes to take out temporary car insurance the insurance
                    companies will require a number of supporting documents:
                  </p>
                  <ul>
                    <li>
                      <img src={righticon} alt="" />A copy of the registration
                      certificate of the insured vehicle.
                    </li>
                    <li>
                      <img src={righticon} alt="" />A copy of the valid UK or
                      European driving license.
                    </li>
                    <li>
                      <img src={righticon} alt="" />A copy of your current
                      passport.
                    </li>
                    <li>
                      <img src={righticon} alt="" />A utility bill valid in the
                      last 3 months.
                    </li>
                  </ul>
                  <p>
                    Temporary car insurance contracts are third party solution
                    to be able to drive the car that can’t be insured in the UK
                    or in any other countries due to the foreign plates on the
                    car. They offer the basic guarantees and insures driver’s
                    civil liability in the event of a claim and legal
                    protection.
                  </p>
                </div>
                <div className="insurance-text">
                  <h3>HOW MUCH DOES TEMPORARY AUTO INSURANCE COST?</h3>
                  <p>
                    The price of temporary car insurance is tailor-made,
                    according to various criteria, such as the driver’s profile,
                    his driving experience, the type of vehicle, etc.
                  </p>
                  <p>
                    Temporary car insurance is often more expensive than
                    traditional car insurance and can only be used as the last
                    solution in case if you cannot find adequate car insurance
                    for your trip abroad.
                  </p>
                  <p>
                    We are only acting as an agent in the UK and will not be
                    held responsible for any breach of contracts as the
                    liabilities will be between you and the insurance company in
                    case of a claim.
                  </p>
                </div>
              </div>
            </div>
            <div className="european-insurance-form">
              <div className="container">
                <h2>EUROPEAN INSURANCE</h2>

                <div className="insurance-group">
                  <div className="form-group">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={credentials.name}
                      onChange={handleChange}
                      placeholder="NAME"
                      required
                      className="form-control"
                    />
                    {error && error.name.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.name.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={credentials.email}
                      onChange={handleChange}
                      placeholder="EMAIL"
                      required
                      className="form-control"
                    />
                    {error && error.email.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.email.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="phone"
                      id="phone"
                      name="phone"
                      value={credentials.phone}
                      onChange={handleChange}
                      placeholder="PHONE"
                      required
                      className="form-control"
                    />
                    {error && error.phone.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.phone.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group textarea">
                    <textarea
                      id="message"
                      name="message"
                      value={credentials.message}
                      onChange={handleChange}
                      placeholder="MESSAGE"
                      required
                    />
                  </div>
                  <button
                    className="primary-btn sm"
                    type="SEND MESSAGE"
                    title="SEND MESSAGE"
                    onClick={handleSubmit}
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EuropeanInsurance;
