import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const homeService = {
  getCarsByTenantId,
  getBrandsByTenantId,
};

function getCarsByTenantId() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getCars/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getBrandsByTenantId() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getBrands/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}
